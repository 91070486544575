import Header from './components/Header'
import Hero from './components/Hero'
import Experience from './components/Experience'
import MusicGrid from './components/MusicGrid';

function App() {
  return (
    <div className="App text-white overflow-hidden">
      <Header/>
      <Hero />
      <Experience />
      <MusicGrid />
    </div>
  );
}

export default App;
