//react imports
import './MusicGrid.css';
import React, { useEffect } from "react"
import { useState } from "react";
import { withTheme } from "styled-components";



//bootstrap imports
import 'bootstrap/dist/css/bootstrap.min.css';

//Asset import

import Waveformneu from "../components/Waveformneu.js";
import { HiDownload } from "react-icons/hi";
import { FiPlay } from "react-icons/fi";






function MusicGrid(){

 
        return(
          
           <><div class="upper "></div>
          <div class="upper1 "><h1>PRELISTEN THE TRACKS</h1></div>
           <div class="playlist-item">
            <div class="left">
              <div class="test2">
                <p>
                  01
                </p>
              </div>
              <div class="test">
                <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                <div class="play-btn">
                <FiPlay />
                  <neu />
                </div>
              </div>
              <div class="test3 col-3">
                <h5>
                  Artifiial Irrelephants
                </h5>
                <p>
                  Syqem
                </p>
              </div>

              <div class="tes">

                <Waveformneu source="https://bafybeigexqcopzs63b7c2r7z7lhbhantrpktxheiwbvxlqpv2kn37ywvli.ipfs.w3s.link/01%20Artificial%20Irrelephants.mp3"/>

              </div>
            </div>
            <div class="center">
              <p>
                3:17
              </p>
            </div>
            <div class="right">
              <div>
              <HiDownload/>

              </div>
              <div>
              <HiDownload/>
              </div>
            </div>
          </div><div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    02
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Shadows = Light
                  </h5>
                  <p>
                    Syqem
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeig5wngbm2mn4fe52gleleprja3niyd5rzmfxh3nyvvewfbmcupmk4.ipfs.w3s.link/02%20Shadows%3DLight.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>

<a href="https://lockurl.netlify.app/#eyJ2IjoiMC4wLjEiLCJlIjoiTkxOVWRicjZWa3Q0T3k1aHcyMi9rc09LQUsyaVRFdk0zLzBiZHhZQWpFUzlBNUZacDNUNFY3OHhUTlpVMUQzSHdqRGk1QWNiOW1QSHp6VUFlS2xocVIvOGxTUC9uYlZ4emxqVU96U09nazRIdVhlWjVIb1g2TW9HOVFhV3prb1hMdFJLcUhiSWVqdnF4YnoyL0tsUnJTRmdFbjc2b1ZHb0srYTFDTWpBQXpMbXNTWVBrRG9zdVJQcENWekVXVVV1TnBWNFdUN0gyTnFpU3p3Z2pTRjBOYW13Mk5Hb1FvTDNQU0I5NGY5SDBJL092SkRxcm9mRnVCMVR2UT09IiwicyI6Inl6L1lNa0FDZC95WUlaNmo3TTVxSEE9PSIsImkiOiJsZnFUN2hxQ0l2UFNDNGFwIn0=" download="MyExampleDoc" target='_blank'>
   <HiDownload/>
</a>
                

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    03
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Inside
                  </h5>
                  <p>
                    Syqem
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeicffhx5xat6xwotexxhztvcc7dnrzjajnrtzhy22ieswtcshthrea.ipfs.w3s.link/03%20Inside.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
            <div class="left">
              <div class="test2">
                <p>
                  04
                </p>
              </div>
              <div class="test">
                <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                <div class="play-btn">
                <FiPlay/>
                  <neu />
                </div>
              </div>
              <div class="test3 col-3">
                <h5>
                  Debris
                </h5>
                <p>
                  Syqem
                </p>
              </div>

              <div class="tes">

                <Waveformneu source="https://bafybeigr676wj3u5gcdsaom6b25jwh2qs44yxojrrg33sk5qqutup4irje.ipfs.w3s.link/04%20Debris.mp3" />
              </div>
            </div>
            <div class="center">
              <p>
                3:17
              </p>
            </div>
            <div class="right">
              <div>
              <HiDownload/>

              </div>
              <div>
              <HiDownload/>
              </div>
            </div>
          </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    05
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Remember Me
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeiejdt6boytydunppqh7dt7mfmbvpvnf7vp57jsirjatvu7l2zgot4.ipfs.w3s.link/09%20Remember%20Me.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    06
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Fucked Up
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeicrc4uluyv62qdjt6aqdp5t45lkknurjs5pirdn5raoaxo7kolhwm.ipfs.w3s.link/06%20Fucked%20Up.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    07
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    (Still) Fucked Up
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeicfw3xdhex2575vk3j5nulz2fsfzn26fqefb7ccwx6lsa4lfgl5pu.ipfs.w3s.link/07%20(Still)%20Fucked%20Up.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    08
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    One Last Time
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeigzc2w2zvxkipdnli7rvakobg2537pqilttuxqlcywfe4b72z6u34.ipfs.w3s.link/08%20One%20Last%20Time.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    09
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Forever Till The End
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeieuc4zhz57mgnmegk5gaafkm6hgxc2h7noa4rwpmvtsrew3s4umvi.ipfs.w3s.link/05%20Forever%20Till%20The%20End.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    10
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Dopamine
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeigeibayoeuizfdv2jfafzfgusakyalpwygmnfqgvqdisw6wjt35rq.ipfs.w3s.link/10%20Dopamine.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    11
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    How To Get Enlightened
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeifm67vthu7sk2dzvfp5qppkrlqda6644wwavubmrddnhprrfg3ynu.ipfs.w3s.link"/>
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    12
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    Dopamine (Remix)
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>


                <div class="tes">
                  <Waveformneu source="https://bafybeihcepfpzt6i2oo3kf7jjc2aq7gvcky4fdeidrhpmm6lankmj5wzlq.ipfs.w3s.link/12%20Dopamine%20(The%20Delta%20Mode%20Remix).mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                <HiDownload/>
                </div>
              </div>
            </div>
            <div class="playlist-item">
              <div class="left">
                <div class="test2">
                  <p>
                    13
                  </p>
                </div>
                <div class="test">
                  <img src="https://bafybeiafkb3tz6n2gjt7i2w47z4lxvchpbgsxbxd7wahqzy57xgqpto6vy.ipfs.w3s.link/sq.jpg" />
                  <div class="play-btn">
                  <FiPlay/>
                  </div>
                </div>
                <div class="test3 col-3">
                  <h5>
                    The Reason
                  </h5>
                  <p>
                    SYQEM
                  </p>
                </div>

                <div class="tes">
                  <Waveformneu source="https://bafybeia6gpkzhzjiemgwkxvr34f3c4ukx6atizzukm2mm6al5z2q2kg3qm.ipfs.w3s.link/13%20Why%20You%20Bought%20This%20CD%20Even%20Though%20You%20Have%20No%20CD%20Player.mp3" />
                </div>
              </div>
              <div class="center">
                <p>
                  3:17
                </p>
              </div>
              <div class="right">
                <div>
                <HiDownload/>

                </div>
                <div>
                 <HiDownload/>
                </div>
              </div>
            </div>
            <div class="upper"></div></>
            


        
            
            
        )
        }
  
export default MusicGrid

